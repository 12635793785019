import { TutorialsType } from 'models/tutorials'
import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class GeneralApi extends ApiCore {
  async getListTutorials(payload: Payload) {
    return await this.get({
      path: '/tutorials',
      payload: {
        ...payload,
        direction: 'asc',
        sort: 'id',
        limit: 100,
      },
    })
  }

  async addTutorial(payload: Payload & TutorialsType) {
    return await this.post({
      path: '/tutorials',
      payload,
    })
  }

  async getTutorialsDetails(id: string | number) {
    return await this.get({
      path: `/tutorials/${id}`,
    })
  }

  async updateTutorial(id: string | number, payload: Payload & TutorialsType) {
    return await this.put({
      path: `/tutorials/${id}`,
      payload,
    })
  }

  async deleteTutorial(id: string | number) {
    return await this.delete({
      path: `/tutorials/${id}`,
    })
  }

  async getCommunityGuidelines() {
    return await this.get({
      path: 'general-information/3',
    })
  }

  async updateCommunityGuidelines(payload: Payload) {
    return await this.patch({
      path: 'general-information/3',
      payload,
    })
  }

  async getSafetyTips() {
    return await this.get({
      path: 'general-information/0',
    })
  }

  async updateSafetyTips(payload: Payload) {
    return await this.patch({
      path: 'general-information/0',
      payload,
    })
  }

  async getTermsOfUse() {
    return await this.get({
      path: 'general-information/2',
    })
  }

  async updateTermsOfUse(payload: Payload) {
    return await this.patch({
      path: 'general-information/2',
      payload,
    })
  }

  async getSafetyGuidelinesOrganizer() {
    return await this.get({
      path: 'general-information/4',
    })
  }

  async updateSafetyGuidelinesOrganizer(payload: Payload) {
    return await this.patch({
      path: 'general-information/4',
      payload,
    })
  }
}

export const generalApi = new GeneralApi()
