import { OptionProps } from 'components'
import {
  EventStatusType,
  EventLabelType,
  EventCategoryType,
  UserTypeConfig,
  CurrencyType,
} from 'types/global.types'
import { charity, company, individual } from 'assets/images'

export const NOT_AVAILABLE_TEXT = 'N/A'

export const MIN_PRICE: Record<string, number> = {
  idr: 50000,
  usd: 3,
  eur: 3,
}

export const USER_TYPE: Record<string, UserTypeConfig> = {
  SUPER_ADMIN: {
    level: 0,
    label: 'super-admin',
    value: 'super-admin',
  },
  ADMIN: {
    level: 1,
    label: 'admin',
    value: 'admin',
  },
}

export const CHALLENGE_CATEGORYS_OPTIONS: OptionProps<EventCategoryType>[] = [
  { value: 'wellness', label: 'Wellness', icon: 'icon-meditation', color: 'text-primary-yellow' },
  { value: 'sport', label: 'Sport', icon: 'icon-streght', color: 'text-primary-red' },
  { value: 'health', label: 'Health', icon: 'icon-heart-rate', color: 'text-primary-blue' },
  { value: 'adventure', label: 'Adventure', icon: 'icon-adventure', color: 'text-primary-green' },
]

export const BANK_LIST: OptionProps[] = [
  { value: 'bri', label: 'Bank BRI' },
  { value: 'bca', label: 'Bank BCA' },
  { value: 'bni', label: 'Bank BNI' },
  { value: 'jago', label: 'Bank JAGO' },
]

export const CHALLENGE_STATUS_OPTIONS: OptionProps<EventStatusType>[] = [
  { value: 'finished', label: 'Finished' },
  { value: 'published', label: 'Published' },
  { value: 'in review', label: 'In Review' },
  { value: 'draft', label: 'Draft' },
]

export const EVENT_LABEL_OPTIONS: OptionProps<EventLabelType>[] = [
  { value: 'outdoor event', label: 'Outdoor', icon: 'icon-tree' },
  { value: 'indoor event', label: 'Indoor', icon: 'icon-home-open' },
  { value: 'virtual event', label: 'Virtual', icon: 'icon-virtual' },
]

export const EVENT_PRICE_OPTIONS: OptionProps<CurrencyType>[] = [
  { value: 'usd', label: 'USD' },
  { value: 'idr', label: 'IDR' },
  { value: 'eur', label: 'EUR' },
]

export const CHECK_IN_STATUS_OPTIONS: OptionProps[] = [
  { value: 'attended', label: 'Attended' },
  { value: 'checkin', label: 'Check In' },
]

export const LIST_ORGANIZATION_TYPE = [
  {
    type: 1,
    title: 'Individual',
    description: 'A person looking to host an event.',
    image: individual,
  },
  {
    type: 2,
    title: 'Company',
    description: 'A legal entity registered in a jurisdiction.',
    image: company,
  },
  {
    type: 3,
    title: 'Charity',
    description:
      'A charitable organisation, or trust, set up to provide help and raise money for those in need.',
    image: charity,
  },
]

export const APP_URLS = {
  android: 'https://play.google.com/store/apps/details?id=com.great.lifestyle',
  ios: 'https://apps.apple.com/id/app/great-app-ai/id6502809306',
}

export const ROLE_OPTIONS: OptionProps[] = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Organizer/Participant' },
]

export const EVENT_ADMIN_STATUS: OptionProps[] = [
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'pending', label: 'Pending' },
]

export const EVENT_PRICE_STATUS: OptionProps[] = [
  { value: 'yes', label: 'Free Events' },
  { value: 'no', label: 'Paid Events' },
]

export const SAFETY_TIPS_STATUS: OptionProps[] = [
  { value: 'yes', label: 'Use Generic Health and Safety Guidelines' },
  { value: 'no', label: 'Use my own Safety Tips and Guidelines' },
]

export const defaultSafetyTipsAndGuidelines =
  '<p style="text-align:center;">- Best practice for attending a Great Event -</p>'
